import React from 'react';
import { formatDate } from 'components/utils/helpers';
import classnames from 'classnames';
import Image from 'components/base/Image';

const TechnicalReport = ({ data, className = '' }) => {
  return (
    <li className={`TechnicalReport pv4 ${className}`}>
      <div className="flex flex-column-reverse flex-row-md">
        <div className="TechnicalReport__description w-100 w-75-md body-copy pr0 pr3-md pr4-xl pr5-xxl mt3 mt0-md">
          <div className="TechnicalReport__line-item flex flex-column flex-row-md">
            <div className="w-100 w-30-md">Author:</div>
            <div className="w-100 w-70-md">{data.author}</div>
          </div>
          <div className="TechnicalReport__line-item flex flex-column flex-row-md mt3">
            <div className="w-100 w-30-md">Title:</div>
            <div className="w-100 w-70-md">{data.title}</div>
          </div>
          <div className="TechnicalReport__line-item flex flex-column flex-row-md mt3">
            <div className="w-100 w-30-md">Published:</div>
            <div className="w-100 w-70-md">
              {formatDate(new Date(data.published))}
            </div>
          </div>
          <div className="TechnicalReport__line-item flex flex-column flex-row-md mt3">
            <div className="w-100 w-30-md">Abstract:</div>
            <div className="w-100 w-70-md">
              <p>{data.abstract.abstract}</p>
            </div>
          </div>
        </div>
        <div className="TechnicalReport__images body-copy w-100 w-25-md pl0 pl3-md pl4-xl pl5-xxl">
          <div className="tc">
            {data.displayImage && data.displayImage.fluid && (
              <figure>
                <Image image={data.displayImage} />
              </figure>
            )}
            <a
              href={data.linkUrl ? data.linkUrl : data.pdf.file.url}
              target="_blank"
              rel="noopener noreferrer"
              className={classnames('gradient-hover dib', {
                mt3: data.displayImage
              })}
            >
              Download
            </a>
          </div>
        </div>
      </div>
    </li>
  );
};

export default TechnicalReport;
