import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import classnames from 'classnames';
import Resident from 'components/modules/Resident';
import TechnicalReport from 'components/modules/TechnicalReport';
import BlockRichText from 'components/modules/BlockRichText';
import BlockTextText from 'components/modules/BlockTextText';
import BlockImageText from 'components/modules/BlockImageText';
import BlockLinksList from 'components/modules/BlockLinksList';

const Projects = ({ data }) => {
  //get the last item in the nodes - this is in case they have a draft version
  //of a new projects page
  const projectsPage = data.allContentfulProjects.nodes.slice(-1)[0];
  const { introDescription, residents, technicalReports } = projectsPage;

  // State for residents, sorted by date
  const [residentsSorted, setResidentsSorted] = useState([]);

  // State for number of residents to show
  const [residentsIndex, setResidentsIndex] = useState(5);
  const residentsIndexIncrementer = 5;
  // State for number of reports to show
  const [reportsIndex, setReportsIndex] = useState(5);
  const reportsIndexIncrementer = 5;

  // Set the residents in order by date
  useEffect(() => {
    setResidentsSorted(
      residents.sort((a, b) => new Date(b.date) - new Date(a.date))
    );
  }, [residents, setResidentsSorted]);

  return (
    <Layout pageTitle="Projects">
      <article className="container container--thin container--boxed">
        {/* an intro description block */}
        {introDescription && (
          <>
            {introDescription.__typename === 'ContentfulBlockRichText' && (
              <BlockRichText data={introDescription} className="bb" />
            )}
            {introDescription.__typename === 'ContentfulBlockTextText' && (
              <BlockTextText data={introDescription} className="bb" />
            )}
            {introDescription.__typename === 'ContentfulBlockImageText' && (
              <BlockImageText data={introDescription} className="bb" />
            )}
          </>
        )}
        {/* Residents list block */}
        <section className="block bb pv3 pv4-xl pv5-xxl">
          <h3 className="body-header ph3 ph4-xl ttu">Residents</h3>
          <ul className="ph3 ph4-xl mt6">
            {residentsSorted.slice(0, residentsIndex).map((resident, index) => {
              return <Resident key={index} data={resident} />;
            })}
          </ul>
          {residentsSorted.length > residentsIndex && (
            <section className="tc">
              <button
                className="gradient-hover ph3 ph4-xl"
                onClick={() => {
                  setResidentsIndex(residentsIndex + residentsIndexIncrementer);
                }}
              >
                Show more residents...
              </button>
            </section>
          )}
        </section>
        {/* Technical reports block */}
        <section className="block pv3 pv4-xl pv5-xxl">
          <h3 className="body-header ph3 ph4-xl ttu">MAL Technical Reports</h3>
          <ul className="ph3 ph4-xl mt6">
            {technicalReports.slice(0, reportsIndex).map((report, index) => {
              return (
                <TechnicalReport
                  key={index}
                  data={report}
                  className={classnames({
                    'bt mt4': index !== 0
                  })}
                />
              );
            })}
          </ul>
          {technicalReports.length > reportsIndex && (
            <section className="tc">
              <button
                className="gradient-hover ph3 ph4-xl"
                onClick={() => {
                  setReportsIndex(reportsIndex + reportsIndexIncrementer);
                }}
              >
                Show more reports...
              </button>
            </section>
          )}
        </section>
        {/* Links list block */}
        <BlockLinksList isNavList={true} className="bt" />
      </article>
    </Layout>
  );
};

export default Projects;

export const query = graphql`
  query projectsPage {
    allContentfulProjects {
      nodes {
        title
        introDescription {
          __typename
          title
          ... on Node {
            ... on ContentfulBlockRichText {
              description {
                json
              }
              twoColumn
            }
            ... on ContentfulBlockTextText {
              textLeft {
                json
              }
              textRight {
                json
              }
            }
            ... on ContentfulBlockImageText {
              image {
                description
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              text {
                json
              }
              setTextToLeft
            }
          }
        }
        residents {
          ... on Node {
            ... on ContentfulBlockResident {
              title
              date
              imageTextBlock {
                __typename
                ... on Node {
                  ... on ContentfulBlockImageText {
                    title
                    image {
                      description
                      fluid(maxWidth: 600) {
                        ...GatsbyContentfulFluid_withWebp
                      }
                    }
                    setTextToLeft
                    text {
                      json
                    }
                  }
                  ... on ContentfulBlockTextText {
                    title
                    textLeft {
                      json
                    }
                    textRight {
                      json
                    }
                  }
                  ... on ContentfulBlockRichText {
                    title
                    description {
                      json
                    }
                    twoColumn
                  }
                }
              }
            }
          }
        }
        technicalReports {
          ... on Node {
            ... on ContentfulBlockTechnicalReport {
              author
              title
              published
              abstract {
                abstract
              }
              displayImage {
                description
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              pdf {
                file {
                  url
                  fileName
                }
              }
              linkUrl
            }
          }
        }
      }
    }
  }
`;
