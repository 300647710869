import React from 'react';
import classnames from 'classnames';
import { formatDate, richText } from 'components/utils/helpers';
import Image from 'components/base/Image';

const Resident = ({ data }) => {
  // Accordion toggler
  const toggleAccordion = e => {
    let accordion = e.currentTarget.parentElement;
    accordion.classList.toggle('Accordion--collapse');
  };

  return (
    <li className="Resident Accordion Accordion--collapse pv2 bt">
      <button
        className="Resident__head Accordion__head db w-100 flex justify-between body-subheader gradient-hover"
        onClick={toggleAccordion}
      >
        <div className="Resident__name">{data.title}</div>
        <div className="flex">
          {data.date && (
            <div className="Resident__year mr2">
              {formatDate(new Date(data.date))}
            </div>
          )}
          <div className="Accordion__arrow flex justify-between items-center">
            <span className="db"></span>
            <span className="db"></span>
          </div>
        </div>
      </button>
      <div className="Resident__body Accordion__body mv4 mv6-lg">
        {data.imageTextBlock.map((block, index) => {
          return (
            <div key={index}>
              {block.__typename === 'ContentfulBlockRichText' && (
                <div
                  className={classnames('Resident__description', {
                    'mt4 mt6-lg': index !== 0
                  })}
                >
                  <p className="ttu">{block.title}</p>
                  <div className="mt3 flex flex-column flex-row-md body-copy">
                    <div
                      className={classnames('w-100 body-copy', {
                        'body-copy__two-column': block.twoColumn,
                        'w-50-md pr3-md pr4-xl pr5-xxl': !block.twoColumn
                      })}
                    >
                      {richText(block.description.json)}
                    </div>
                  </div>
                </div>
              )}
              {block.__typename === 'ContentfulBlockTextText' && (
                <div
                  className={classnames('Resident__description', {
                    'mt4 mt6-lg': index !== 0
                  })}
                >
                  <p className="ttu">{block.title}</p>
                  <div className="mt3 flex flex-column flex-row-md body-copy">
                    <div className="w-100 w-50-md body-copy pr3-md pr4-xl pr5-xxl">
                      {richText(block.textLeft.json)}
                    </div>
                    <div className="w-100 w-50-md body-copy pl3-md pl4-xl pl5-xxl">
                      {richText(block.textRight.json)}
                    </div>
                  </div>
                </div>
              )}
              {block.__typename === 'ContentfulBlockImageText' && (
                <div
                  className={classnames(
                    'flex flex-column flex-row-md body-copy',
                    {
                      'mt4 mt6-lg': index !== 0
                    }
                  )}
                >
                  <div className="Resident__description w-100 body-copy pr0 w-50-md pr3-md pr4-xl pr5-xxl">
                    <p className="ttu">{block.title}</p>
                    {richText(block.text.json)}
                  </div>
                  {block.image && (
                    <div className="Resident__images w-100 w-50-md mt4 mt0-md pl0 pl3-md pl4-xl pl5-xxl">
                      <Image image={block.image} />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </li>
  );
};

export default Resident;
